import { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import UserContext from '../context/usercontext';
import '../index.css';
export default function Navbars() {
  const { user, setUser } = useContext(UserContext);

  return (
    <Navbar
      dir="rtl"
      variant="dark"
      expand="lg"
      style={{
        marginBottom: '40px',
        backgroundColor: '#5B5891',
        fontFamily: 'Vazirmatn',
      }}>
      {user?.role === 'admin' ? (
        <Container
          style={{
            fontFamily: 'Vazirmatn',

            fontSize: '20px',
          }}>
          <Navbar.Brand href="/">پنل مدیریت کاربران</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {/* <Nav.Link href="allworkouts">All Exercises</Nav.Link>
              <Nav.Link href="/newplans">Active Excercises</Nav.Link> */}

              <Nav.Link href="/users">مدیریت کاربران</Nav.Link>
              <Nav.Link href="/Sessions">مدیریت محتوای دوره</Nav.Link>
              <Nav.Link href="/emailsender"> ارسال ایمیل</Nav.Link>
              <Nav.Link href="/appusers">App Users</Nav.Link>
              <Nav.Link href="/orders">سفارشات</Nav.Link>

              <Nav.Link
                onClick={() => {
                  localStorage.removeItem('credentials');
                  setUser(null);
                  window.open('/login', '_self');
                }}>
                خروج از پنل
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      ) : null}
    </Navbar>
  );
}
