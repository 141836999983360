import React, { useEffect, useState } from 'react';
import { Accordion, Card, Col, ListGroup, Row, Spinner } from 'react-bootstrap';
import '../../../index.css';
import api from '../../../apis/api';
function UserBasicData({ userId, userName }) {
  const [userData, setUserData] = useState(null);
  console.log(userData);
  const [status, setStatus] = useState('idle');
  const handleUserData = async () => {
    setStatus('loading');
    try {
      const response = await api.get(`/ShapeUpAssessment/${userId}`);
      setUserData(response.data.data[0]);
      setStatus('success');
    } catch (error) {
      console.log(error);
      setStatus('error');
    }
  };

  useEffect(() => {
    handleUserData();
  }, [userId]);

  return (
    <Row
      style={{
        fontFamily: 'Vazirmatn',
      }}
      dir="rtl"
      className=" justify-content-between align-items-center border border-1 p-3 rounded">
      {status === 'loading' && <Spinner animation="border" />}

      <ListGroup.Item>
        <h2> {userName}</h2>
      </ListGroup.Item>

      <Col>
        <ListGroup.Item>
          <hr></hr>
        </ListGroup.Item>
        <ListGroup.Item>
          <p> سن: {userData?.age}</p>
        </ListGroup.Item>
        <hr></hr>
        <ListGroup.Item>
          <p> وزن اولیه : {userData?.weight}</p>
        </ListGroup.Item>

        <hr></hr>
        <ListGroup.Item>
          <p> قد : {userData?.height}</p>
          <hr></hr>
        </ListGroup.Item>

        {userData?.country && (
          <ListGroup.Item>
            <p> درد یا مشکل خاص: {userData?.pain}</p>
            <hr></hr>
          </ListGroup.Item>
        )}
        {userData?.illnessName && (
          <ListGroup.Item>
            <p> {userData?.illnessName}</p>
            <hr></hr>
          </ListGroup.Item>
        )}

        {userData?.painName && (
          <ListGroup.Item>
            <p>{userData?.painName}</p>
            <hr></hr>
          </ListGroup.Item>
        )}

        {userData?.reasonToNotWorkout && (
          <ListGroup.Item>
            <p>{userData?.reasonToNotWorkout}</p>
            <hr></hr>
          </ListGroup.Item>
        )}

        {/* {userWorkOutData?.workoutPlace && (
            <ListGroup.Item>
              <p>{userWorkOutData?.workoutPlace}</p>
            </ListGroup.Item>
          )} */}
        {userData?.dayOfStrengthTraining && (
          <ListGroup.Item>
            <p>{userData?.dayOfStrengthTraining} روز ورزش قدرتی در هفته</p>
            <hr></hr>
          </ListGroup.Item>
        )}

        {userData?.exerciseType && (
          <ListGroup.Item>
            <p>نوع ورزش: {userData?.exerciseType} </p>
            <hr></hr>
          </ListGroup.Item>
        )}
        {/* {userWorkOutData?.workOutExperience && (
            <ListGroup.Item>
              <p>{userWorkOutData?.workOutExperience}</p>
            </ListGroup.Item>
          )} */}

        {userData?.dietName && (
          <ListGroup.Item>
            <p>رژیم: {userData?.dietName}</p>
            <hr></hr>
          </ListGroup.Item>
        )}
        {userData?.lastTimeDiet && (
          <ListGroup.Item>
            <p>آخرین رژیم : {userData?.lastTimeDiet}</p>
          </ListGroup.Item>
        )}

        <ListGroup.Item>
          <p>رژیم فعلی : {userData?.currentDiet}</p>
          <hr></hr>
        </ListGroup.Item>

        {userData?.comment && (
          <ListGroup.Item>
            <p> توضیحات: {userData?.comment}</p>
            <hr></hr>
          </ListGroup.Item>
        )}
      </Col>
    </Row>
  );
}

export default UserBasicData;
