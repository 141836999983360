import React from 'react';
import { useEffect, useState } from 'react';
import { Button, Form, InputGroup, Stack } from 'react-bootstrap';
import api from '../../../apis/api';
import '../../../index.css';
import moment from 'moment';
function CoachReport({ userId, name }) {
  const coach = 'admin';
  const coachName = 'Azar';
  const [rate, setRate] = useState(0);
  const [reportId, setReportId] = useState('');
  const [nextgoal, setNextGoal] = useState('');
  const [steps, setSteps] = useState(0);
  const [fiber, setFiber] = useState(0);
  const [exercise, setExercise] = useState(0);
  const [calories, setCalories] = useState(0);
  const [carbs, setCarbs] = useState(0);
  const [fat, setFat] = useState(0);
  const [protein, setProtein] = useState(0);
  const [maintainCalorie, setMaintainCalorie] = useState(0);
  const [sendReportStatus, setSendReportStatus] = useState('idle');
  const [lastCoachResponses, setLastCoachResponses] = useState([]);
  const [proteinAmount, setProteinAmount] = useState(0);
  const [carbsAmount, setCarbsAmount] = useState(0);
  const [fatAmount, setFatAmount] = useState(0);

  const percent = [
    { value: 20, label: '20%' },
    { value: 25, label: '25%' },
    { value: 30, label: '30%' },
    { value: 35, label: '35%' },
    { value: 40, label: '40%' },
    { value: 45, label: '45%' },
    { value: 50, label: '50%' },
    ,
  ];
  const handleChangeResponse = (e) => {
    setNextGoal(e.target.value);
  };
  const handleCalories = (e) => {
    setCalories(e.target.value);
  };
  const handleMaintainCalorie = (e) => {
    setMaintainCalorie(e.target.value);
  };
  const handleProtein = (e) => {
    setProtein(e.target.value);
  };
  const handleCarbs = (e) => {
    setCarbs(e.target.value);
  };
  const handleFat = (e) => {
    setFat(e.target.value);
  };
  const handleFiber = (e) => {
    setFiber(e.target.value);
  };
  const handleSteps = (e) => {
    setSteps(e.target.value);
  };
  const handleExercise = (e) => {
    setExercise(e.target.value);
  };

  const handleGetCoachreports = async () => {
    api.get(`/coachresponse/${userId}`).then((res) => {
      console.log(res.data);
      setLastCoachResponses(res.data);
    });
  };

  const calculateGrams = () => {
    // Calculate calories for each macronutrient
    const proteinCalories = (protein / 100) * calories;
    const fatCalories = (fat / 100) * calories;
    const carbsCalories = (carbs / 100) * calories;

    // Calculate grams for each macronutrient
    setProteinAmount(proteinCalories / 4); // 4 calories per gram of protein
    setFatAmount(fatCalories / 9); // 9 calories per gram of fat
    setCarbsAmount(carbsCalories / 4); // 4 calories per gram of carbs
  };

  useEffect(() => {
    handleGetCoachreports();
  }, []);

  useEffect(() => {
    calculateGrams();
  }, [protein, fat, carbs, calories]);

  const checkEmpty = () => {
    if (
      !nextgoal ||
      !steps ||
      !fiber ||
      !exercise ||
      !calories ||
      !carbs ||
      !fat ||
      !protein ||
      !maintainCalorie
    ) {
      alert('لطفا تمام فیلد ها را پر کنید');
      return false;
    }
    return true;
  };

  const checkPercent = () => {
    //check the value of protein, carbs, fat it should be 100
    // if not return
    //sum up the value of protein, fat and carbs

    const value = Number(protein) + Number(fat) + Number(carbs);
    console.log(value);
    if (value !== 100) {
      alert('مجموع مقادیر پروتئین، چربی و کربوهیدرات باید 100 باشد');
      return false;
    }
    return true;
  };

  const handleSendResponse = () => {
    if (!checkEmpty()) return;
    if (!checkPercent()) return;
    setSendReportStatus('loading');
    api
      .post(`/coachresponse`, {
        coach,
        coachName,
        rate,
        reportId,
        nextgoal,
        protein,
        fat,
        carbs,
        calories,
        steps,
        fiber,
        maintainCalorie,
        exercise,
        userName: name,
        userId: userId,
      })
      .then((res) => {
        console.log(res.data);
        setSendReportStatus('success');
        alert('Response sent successfully');
        handleGetCoachreports();
      })
      .catch((err) => {
        setSendReportStatus('error');
        console.log(err);
        alert(err.message);
        handleGetCoachreports();
      })
      .finally(() => {
        handleGetCoachreports();
        setSendReportStatus('idle');
        handleGetCoachreports();
      });
  };

  return (
    <div
      style={{
        border: '0.5px solid gray',
        borderRadius: '10px',
        marginTop: '10px',
        marginBottom: '10px',
        minHeight: '6em',
        padding: '1em',
        cursor: 'text',
        fontFamily: 'Vazirmatn',
      }}>
      <Form style={{ width: '100%' }}>
        <InputGroup>
          <InputGroup.Text>بررسی و برنامه هفته آینده</InputGroup.Text>
          <Form.Control
            onChange={(e) => handleChangeResponse(e)}
            className="h-100"
            as="textarea"
            aria-label="With textarea"
          />
        </InputGroup>
        <br />
        <InputGroup>
          <InputGroup.Text>
            <Form.Label>کالری تثبیت</Form.Label>
          </InputGroup.Text>
          <Form.Control
            onChange={(e) => handleMaintainCalorie(e)}
            className=""
            as="input"
            aria-label="With textarea"
          />
        </InputGroup>
        <br />
        <InputGroup>
          <InputGroup.Text>
            <Form.Label>کالری روزانه</Form.Label>
          </InputGroup.Text>
          <Form.Control
            onChange={(e) => handleCalories(e)}
            className=""
            as="input"
            aria-label="With textarea"
          />
        </InputGroup>
        <br />
        <InputGroup>
          <InputGroup.Text>
            <Form.Label>
              میزان پروتئین
              <br />
              <span style={{ fontSize: '0.8em', color: 'gray' }}>
                {proteinAmount.toFixed(2)} گرم
              </span>
            </Form.Label>
          </InputGroup.Text>
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => handleProtein(e)}>
            <option>انتخاب کنید</option>
            {percent.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              );
            })}
          </Form.Select>
        </InputGroup>
        <br />
        <InputGroup>
          <InputGroup.Text>
            <Form.Label>
              میزان کربوهیدرات
              <br />
              <span style={{ fontSize: '0.8em', color: 'gray' }}>
                {carbsAmount.toFixed(2)} گرم
              </span>
            </Form.Label>
          </InputGroup.Text>

          <Form.Select
            aria-label="Default select example"
            onChange={(e) => handleCarbs(e)}>
            <option>انتخاب کنید</option>
            {percent.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              );
            })}
          </Form.Select>
        </InputGroup>
        <br />
        <InputGroup>
          <InputGroup.Text>
            <Form.Label>
              میزان چربی
              <br />
              <span style={{ fontSize: '0.8em', color: 'gray' }}>
                {fatAmount.toFixed(2)} گرم
              </span>
            </Form.Label>
          </InputGroup.Text>

          <Form.Select
            aria-label="Default select example"
            onChange={(e) => handleFat(e)}>
            <option>انتخاب کنید</option>
            {percent.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              );
            })}
          </Form.Select>
        </InputGroup>
        <br />
        <InputGroup>
          <InputGroup.Text>
            <Form.Label>میزان فیبر</Form.Label>
          </InputGroup.Text>
          <Form.Control
            onChange={(e) => handleFiber(e)}
            className=""
            as="input"
            aria-label="With textarea"
          />
        </InputGroup>
        <br />
        <InputGroup>
          <InputGroup.Text>
            <Form.Label>تعداد قدمها</Form.Label>
          </InputGroup.Text>
          <Form.Control
            o
            onChange={(e) => handleSteps(e)}
            className=""
            as="input"
            aria-label="With textarea"
          />
        </InputGroup>
        <br />
        <InputGroup>
          <InputGroup.Text>
            <Form.Label>تعداد روزهای تمرین</Form.Label>
          </InputGroup.Text>
          <Form.Control
            onChange={(e) => handleExercise(e)}
            className=""
            as="input"
            aria-label="With textarea"
          />
        </InputGroup>

        <br />
        <Stack direction="horizontal" gap={3}>
          <InputGroup.Text>امتیاز به عملکرد این هفته ی {name}</InputGroup.Text>
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => setRate(e.target.value)}>
            <option>انتخاب کنید</option>
            <option value="0"> نظری ندارم!</option>
            <option value="1">عملکرد خوب</option>
            <option value="2">عملکرد متوسط</option>
            <option value="3">عملکرد ضعیف</option>
          </Form.Select>
          <Button
            style={{
              backgroundColor:
                sendReportStatus === 'loading' ? 'gray' : 'green',
            }}
            onClick={handleSendResponse}>
            ارسال پاسخ
          </Button>
        </Stack>
        <br />
      </Form>

      <>
        <div className="text-primary cursor-">
          <button
            onClick={handleGetCoachreports}
            type="button"
            class="btn btn-success">
            مشاهده آخرین پاسخ های مربی
          </button>
        </div>

        {lastCoachResponses
          .slice()
          .reverse()
          .map((item, index) => {
            return (
              <>
                <div
                  key={index}
                  style={{
                    border: '0.5px solid gray',
                    borderRadius: '10px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    minHeight: '6em',
                    padding: '1em',
                    cursor: 'text',
                  }}>
                  <p>
                    تاریخ: {moment(item.response_date).format('YYYY/MM/DD')}
                  </p>
                  <p>امتیاز مربی: {item.rate}</p>
                  <p>هدف بعدی: {item.next_Goal}</p>
                  <p>کالری تثبیت: {item.maintainCalorie}</p>
                  <p>کالری روزانه: {item.calories}</p>
                  <p>میزان پروتئین: {item.protein}</p>
                  <p>میزان کربوهیدرات: {item.carbs}</p>
                  <p>میزان چربی: {item.fat}</p>
                  <p>میزان فیبر: {item.fiber}</p>
                  <p>تعداد قدمها: {item.steps}</p>
                  <p>تعداد روزهای تمرین: {item.exercise}</p>
                </div>
              </>
            );
          })}
      </>
    </div>
  );
}

export default CoachReport;
