import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Row,
  Modal,
  Image,
  Carousel,
  Alert,
  Accordion,
} from 'react-bootstrap';
import { Link, Navigate, useLocation } from 'react-router-dom';
import UserContext from '../../../context/usercontext';
import UserBasicData from './userBasicData';
import UpdateUser from './updateUser';
import BeforeAfter from './BeforeAfter';
import api from '../../../apis/api';
import defaultImage from '../../../img/default.png';
import '../../../index.css';
import SizeHistory from './sizes';
import CoachReport from './CoachReport';
import { useSearchParams } from 'react-router-dom';
import { getUserByEmail } from '../../../apis/findUserByEmail';
import FilePreview from '../../../components/FilePreview';
import LastReport from './lastReport';
import OtherReport from './OtherFiles';

const SingleUser = (props) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const userEmail = searchParams.get('email');
  console.log('userEmail', userEmail);
  const handleGetUserData = async () => {
    try {
      const response = await getUserByEmail(userEmail);
      console.log(response.data);
      setSingleUser(response.data);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    handleGetUserData();
  }, []);

  const { user, setUser } = useContext(UserContext);
  const [modalShow, setModalShow] = useState(false);
  const [userReport, setUserReport] = useState([]);
  const [userMonthlyReport, setUserMonthlyReport] = useState([]);
  const [isWeeklyError, setIsWeeklyError] = useState(false);
  const [isMonthlyError, setIsMonthlyError] = useState(false);
  const [singleUser, setSingleUser] = useState({});
  const [isWeeklyEmpty, setIsWeeklyEmpty] = useState(false);
  const [isMonthlyEmpty, setIsMonthlyEmpty] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const [sendReportStatus, setSendReportStatus] = useState('idle');
  //get value of the input field and store it in an object

  const [lastCoachResponses, setLastCoachResponses] = useState([]);
  const { name, userProduct, level, email, _id } = singleUser;

  const handleGetCoachreports = () => {
    api.get(`/coachresponse/${_id}`).then((res) => {
      console.log(res.data);
      setLastCoachResponses(res.data);
    });
  };

  useEffect(() => {
    handleGetCoachreports();
  }, []);
  const handleUserReport = async () => {
    const res = await api
      .get(`/report/report/${_id}`)

      .then((res) => {
        console.log(res);
        setUserReport(res.data);
        if (res.data.length === 0) {
          setIsWeeklyEmpty(true);
          console.log(res.data.length);
        }
      })
      .catch((err) => {
        setIsWeeklyError(true);
        setErrorMsg(err.message);
      });
  };

  const handleDismiss = () => {
    setIsMonthlyError(false);
    setIsWeeklyError(false);
  };
  const getMonthlyReport = () => {
    api
      .get(`/report/sizereport/${_id}`)
      .then((res) => {
        setUserMonthlyReport(res.data);
        console.log(res.data);

        // Check if userMonthlyReport is empty
        if (res.data.length === 0) {
          setIsMonthlyEmpty(true);
          console.log(res.data.length);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsMonthlyError(true);
        setErrorMsg(err.message);
      });
  };

  useEffect(() => {
    getMonthlyReport();
    handleUserReport();
  }, [singleUser]);

  const handleUserUpdate = () => {
    console.log('selectedProduct', selectedProduct);

    api
      .post(`/api/addProductToUser/${_id}`, {
        level: selectedProduct === 'Shape up Academy' ? 0 : 4,
        product: selectedProduct,
      })
      .then((res) => {
        console.log(res.data);
        alert('User updated successfully');
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  };

  const handleUserPaymentType = () => {
    api
      .put(`/api/updateUserPaymentfrompanel/${_id}`, {
        paymentType: paymentType,
      })
      .then((res) => {
        console.log(res.data);
        alert('User updated successfully');
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  };

  return (
    <div
      style={{
        fontFamily: 'Vazirmatn',
      }}>
      {user?.role === 'admin' ? (
        <Container
          dir="rtl"
          style={{
            fontFamily: 'Vazirmatn',
          }}>
          <Row>
            <Col lg={8} xs={12} md={12}>
              <Col lg={12} xs={12} md={12}>
                <Col lg={12} xs={12} md={12}>
                  <Row>
                    <Col>
                      <SizeHistory userId={singleUser._id} />
                      <BeforeAfter userId={singleUser._id} />
                      <OtherReport
                        userId={singleUser._id}
                        userReport={userReport}
                      />
                    </Col>
                  </Row>
                </Col>
              </Col>
              <Col lg={12} xs={12} md={12}>
                <CoachReport userId={singleUser._id} name={singleUser.name} />
              </Col>
            </Col>
            <Col lg={4} xs={12} md={12}>
              {isWeeklyError && (
                <Alert variant="danger" onDismiss={handleDismiss}>
                  {errorMsg}
                </Alert>
              )}

              <UserBasicData
                userId={singleUser._id}
                userName={singleUser.name}
              />
              <LastReport userId={singleUser._id} userReport={userReport} />
            </Col>
          </Row>

          <UpdateUser
            email={email}
            level={level}
            userProduct={userProduct}
            setPaymentType={setPaymentType}
            handleUserPaymentType={handleUserPaymentType}
            setSelectedProduct={setSelectedProduct}
            handleUserUpdate={handleUserUpdate}
          />
          {modalShow && (
            <Modal
              show={modalShow}
              onHide={() => setModalShow(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Modal heading
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <img style={{ width: '100%', height: 'auto' }} alt="dd" />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  disabled={sendReportStatus === 'loading'}
                  onClick={() => setModalShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Container>
      ) : (
        <Navigate to="/login" />
      )}
    </div>
  );
};

export default SingleUser;
