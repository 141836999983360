import { useEffect, useState } from 'react';
import {
  Badge,
  Col,
  Container,
  ListGroup,
  Row,
  Table,
  Button,
  Spinner,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import appApi from '../../apis/appApi';
import '../../index.css';

const AppUserMainPage = () => {
  const [users, setUsers] = useState([]);
  const [status, setStatus] = useState('idle');
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [totalPages, setTotalPages] = useState(1); // Track total pages
  const pageSize = 10; // Number of users per page

  const getUserList = (page = 1) => {
    setStatus('loading');
    appApi
      .get(`/getUsers?page=${page}&limit=${pageSize}`) // Pass page and limit as query params
      .then((res) => {
        setUsers(res.data.users); // Assume response contains { users, totalPages }
        setTotalPages(res.data.totalPages);
        setStatus('success');
      })
      .catch((err) => {
        console.error(err); // Log error for debugging
        setStatus('error');
        alert(err.message);
      });
  };

  useEffect(() => {
    getUserList(currentPage); // Fetch users when the component loads or page changes
  }, [currentPage]);

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  return (
    <Container style={{ fontFamily: 'Vazirmatn' }} className="mt-5 w-50">
      <Row>
        <Col lg={3}>
          <h2 className="text-center">لیست کاربران</h2>
          <br />
          <ListGroup>
            {status === 'loading' && (
              <div className="text-center">
                <Spinner animation="border" />
              </div>
            )}
            {status === 'error' && (
              <h1 className="text-danger text-center">خطا در بارگذاری</h1>
            )}
            {status === 'success' && (
              <>
                <Row>
                  <Col>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>نام</th>
                          <th>ایمیل</th>
                          <th>دوره های ثبت نامی</th>
                          <th>عملیات</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users && users.length > 0 ? (
                          users.map((user) => (
                            <tr key={user._id}>
                              <td>{user.name}</td>
                              <td>{user.email}</td>
                              <td>
                                {user.userProduct?.map((product, index) => (
                                  <Badge key={index} className="me-1">
                                    {product.name}
                                  </Badge>
                                ))}
                              </td>
                              <td>
                                <Link
                                  to={`/appuser/query?email=${user.email}`}
                                  state={user}>
                                  <Button variant="success">
                                    پروفایل کاربر
                                  </Button>
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="4" className="text-center">
                              هیچ کاربری یافت نشد
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>

                {/* Pagination Controls */}
                <Row className="justify-content-center mt-3">
                  <Col className="text-center">
                    <Button
                      variant="primary"
                      className="me-2"
                      onClick={handlePrevious}
                      disabled={currentPage === 1}>
                      صفحه قبلی
                    </Button>
                    <span>
                      صفحه {currentPage} از {totalPages}
                    </span>
                    <Button
                      variant="primary"
                      className="ms-2"
                      onClick={handleNext}
                      disabled={currentPage === totalPages}>
                      صفحه بعدی
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default AppUserMainPage;
