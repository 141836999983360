import React from 'react';
import '../../../index.css';
import FilePreview from '../../../components/FilePreview';
import { Accordion, Alert, Carousel, Col, ListGroup } from 'react-bootstrap';

function LastReport({ userReport }) {
  return (
    <div
      style={{
        marginTop: 20,
      }}>
      <h3
        style={{
          fontFamily: 'Vazirmatn',
        }}>
        گزارش های هفتگی
      </h3>
      <Accordion defaultActiveKey={0}>
        {userReport?.map(
          (item, index) =>
            item.archive !== true &&
            item.type === 'weekly' && (
              <div
                key={index}
                style={{
                  backgroundColor: index === 0 ? '#f0f0f0' : 'transparent',
                }}>
                <Accordion.Item
                  style={{
                    fontFamily: 'Vazirmatn',
                    backgroundColor: index === 0 ? '#f0f0f0' : 'transparent',
                  }}
                  eventKey={index}>
                  <Accordion.Header>
                    تاریخ گزارش:
                    {item?.date
                      ? new Intl.DateTimeFormat('en-US', {
                          dateStyle: 'short',
                          timeStyle: 'short',
                        }).format(new Date(item.date))
                      : null}{' '}
                  </Accordion.Header>
                  <Accordion.Body dir="rtl">
                    <span
                      style={{
                        fontSize: 14,

                        textAlign: 'left',
                      }}>
                      {item?.description}
                    </span>
                    <br></br>
                    <div dir="rtl">
                      <span>
                        ورزش قدرتی:
                        {userReport[userReport.length - 1]?.daysOfStrength} روز
                        در هفته
                        <br />
                        کاردیو:
                        {userReport[userReport.length - 1]?.daysOfCardio} روز در
                        هفته
                      </span>
                    </div>
                    {/* <Button
                                      className="float-end"
                                      variant="warning"
                                      onClick={() =>
                                        handleDeleteWeeklyReport(item._id)
                                      }>
                                      Remove
                                    </Button> */}
                    <Carousel interval={null} slide={false}>
                      {item?.data?.map((item, i) => (
                        <Carousel.Item key={index}>
                          <FilePreview fileUrl={item.url} index={index} />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
            )
        )}
      </Accordion>
    </div>
  );
}

export default LastReport;
