import { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row, Modal, Alert } from 'react-bootstrap';
import { Link, Navigate, useLocation } from 'react-router-dom';
import UserContext from '../../../context/usercontext';

import UpdateUser from './updateUser';

import '../../../index.css';

import { useSearchParams } from 'react-router-dom';
import appApi from '../../../apis/appApi';

const AppSingleUser = (props) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const userEmail = searchParams.get('email');
  console.log('location', location.state);
  const { user, setUser } = useContext(UserContext);
  const [modalShow, setModalShow] = useState(false);
  const [singleUser, setSingleUser] = useState({});
  const [selectedProduct, setSelectedProduct] = useState('');
  const [paymentType, setPaymentType] = useState('');

  //get value of the input field and store it in an object

  const { name, userProduct, level, email, _id } = location.state;

  const handleUserUpdate = () => {
    console.log('selectedProduct', selectedProduct);

    appApi
      .post(`/api/addProductToUser/${_id}`, {
        level: 4,
        product: selectedProduct,
      })
      .then((res) => {
        console.log(res.data);
        alert('User updated successfully');
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  };

  const handleUserPaymentType = () => {
    appApi
      .put(`/api/updateUserPaymentfrompanel/${_id}`, {
        paymentType: paymentType,
      })
      .then((res) => {
        console.log(res.data);
        alert('User updated successfully');
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  };

  return (
    <div
      style={{
        fontFamily: 'Vazirmatn',
      }}>
      {user?.role === 'admin' ? (
        <Container
          dir="rtl"
          style={{
            fontFamily: 'Vazirmatn',
          }}>
          <UpdateUser
            email={email}
            level={level}
            userProduct={userProduct}
            setPaymentType={setPaymentType}
            handleUserPaymentType={handleUserPaymentType}
            setSelectedProduct={setSelectedProduct}
            handleUserUpdate={handleUserUpdate}
          />
          {modalShow && (
            <Modal
              show={modalShow}
              onHide={() => setModalShow(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Modal heading
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <img style={{ width: '100%', height: 'auto' }} alt="dd" />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  // disabled={sendReportStatus === 'loading'}
                  onClick={() => setModalShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Container>
      ) : (
        <Navigate to="/login" />
      )}
    </div>
  );
};

export default AppSingleUser;
