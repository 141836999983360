import React from 'react';
import '../../../index.css';
import FilePreview from '../../../components/FilePreview';
import { Accordion, Carousel, Col, Row } from 'react-bootstrap';

function OtherReport({ userReport }) {
  return (
    <div
      style={{
        marginTop: 20,
      }}>
      <h3
        style={{
          fontFamily: 'Vazirmatn',
        }}></h3>
      <Accordion>
        {userReport?.map(
          (item, index) =>
            item.archive !== true &&
            item.type === 'otherfiles' && (
              <div
                key={index}
                style={{
                  backgroundColor: index === 0 ? '#f0f0f0' : 'transparent',
                }}>
                <Accordion.Item
                  style={{
                    fontFamily: 'Vazirmatn',
                    backgroundColor: index === 0 ? '#f0f0f0' : 'transparent',
                  }}
                  eventKey={index}>
                  <Accordion.Header>
                    <Row>
                      <Col>آزمایشات و بادی انالیز</Col>

                      <Col>
                        {item?.date
                          ? new Intl.DateTimeFormat('en-US', {
                              dateStyle: 'short',
                            }).format(new Date(item.date))
                          : null}{' '}
                      </Col>
                    </Row>
                  </Accordion.Header>
                  <Accordion.Body>
                    <span
                      style={{
                        fontSize: 14,

                        textAlign: 'left',
                      }}>
                      {item?.description}
                    </span>

                    <Carousel interval={null} slide={false}>
                      {item?.data?.map((item, i) => (
                        <Carousel.Item key={index}>
                          <FilePreview fileUrl={item.url} index={index} />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
            )
        )}
      </Accordion>
    </div>
  );
}

export default OtherReport;
