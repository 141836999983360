import React, { useState, useEffect } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Accordion } from 'react-bootstrap';
import '../../../index.css';
import heic2any from 'heic2any';
import api from '../../../apis/api';

const BeforeAfter = ({ userId }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [images, setImages] = useState([]);
  const [status, setStatus] = useState('idle');

  const handleOnSelect = (item) => {
    setStatus('loading');
    const userId = item._id; // Get the user's ID

    // Fetch user's images by ID
    api
      .get(`/report/imagereport/${userId}`)
      .then(async (response) => {
        const imagesWithConvertedUrls = await Promise.all(
          response.data.map(async (imageSet) => {
            const convertedData = await Promise.all(
              imageSet.data.map(async (imgUrl) => {
                if (imgUrl.endsWith('.heic')) {
                  try {
                    const response = await fetch(imgUrl);
                    if (!response.ok) throw new Error('Failed to fetch image');
                    const blob = await response.blob();
                    const convertedBlob = await heic2any({
                      blob,
                      toType: 'image/jpeg',
                    });
                    return URL.createObjectURL(convertedBlob);
                  } catch (error) {
                    console.error('Error converting HEIC image:', error);
                    return imgUrl; // Fallback to original URL if conversion fails
                  }
                }
                return imgUrl;
              })
            );
            return { ...imageSet, data: convertedData };
          })
        );
        setSelectedUser(item);
        setImages(imagesWithConvertedUrls); // Set images to be displayed
        setStatus('done');
      })
      .catch((error) => {
        console.error('Error fetching images:', error);
        setStatus('error');
      });
  };

  useEffect(() => {
    if (userId) {
      handleOnSelect({ _id: userId });
    }
  }, [userId]);

  return (
    <div
      dir="rtl"
      className=" mt-5"
      style={{
        fontFamily: 'Vazirmatn',
      }}>
      {/* Accordion to display selected user's images */}
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            نمایش تصاویر {selectedUser?.name || ''}
          </Accordion.Header>
          <Accordion.Body>
            {status === 'loading' ? (
              <p>Loading images...</p>
            ) : status === 'error' ? (
              <p>Error loading images.</p>
            ) : images.length > 0 ? (
              images?.map((imageSet) => (
                <div key={imageSet._id} className="mb-5">
                  <h5>تاریخ: {new Date(imageSet.date).toLocaleDateString()}</h5>
                  <div className="row">
                    {imageSet.data.map((imgUrl, index) => (
                      <div key={index} className="col-md-3 mb-2">
                        <img src={imgUrl} alt="User" className="thumbnail" />
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <p>No images available for this user.</p>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default BeforeAfter;
